import React, { useEffect, useState } from 'react';
import { Link, navigate } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

const LOCALES = ['en', 'se'];

const buttonStyles = {
    minInlineSize: 140,
    blockSize: 48,
    backgroundColor: '#154734',
    borderRadius: 60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: '-apple-system, Work Sans',
    paddingInline: 20,
    cursor: 'pointer',
};

export const useMediaQuery = (query) => {
    const mediaMatch = window.matchMedia(query);
    const [matches, setMatches] = useState(mediaMatch.matches);

    useEffect(() => {
        const handler = (e) => setMatches(e.matches);
        mediaMatch.addEventListener('change', handler);

        return () => mediaMatch.removeEventListener('change', handler);
    });

    return matches;
};

const getLanguage = (location) =>
    LOCALES.find((locale) => locale === location.pathname.split('/')[1]);

const NotFoundPage = ({ location }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const isMobile = typeof window !== 'undefined' && useMediaQuery('(max-width: 500px)');
    const language = getLanguage(location);

    return (
        <div
            style={{
                padding: isMobile ? 30 : 96,
                fontFamily: '-apple-system, Roboto, sans-serif, serif',
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                justifyContent: 'center',
                alignItems: 'center',
                gap: isMobile ? 30 : 50,
            }}
        >
            <StaticImage
                src="../images/hammarbyLogoWhite.png"
                alt="Hammarby Fotboll"
                style={{
                    maxBlockSize: isMobile ? 170 : 390,
                    maxInlineSize: isMobile ? 170 : 390,
                    opacity: 0.5,
                }}
            />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignSelf: 'stretch',
                    minBlockSize: '100%',
                    paddingBlockStart: isMobile ? '' : 50,
                }}
            >
                <div
                    style={{
                        fontSize: isMobile ? 24 : 48,
                        lineHeight: isMobile ? '32px' : '54px',
                        fontWeight: 700,
                        maxWidth: 500,
                    }}
                >
                    {language === 'en' ? 'Oops!' : 'Hoppsan!'}
                </div>
                <div
                    style={{
                        fontSize: isMobile ? 16 : 24,
                        lineHeight: isMobile ? '24px' : '32px',
                        maxWidth: 500,
                        paddingBlock: 20,
                    }}
                >
                    {language === 'en'
                        ? 'The page you tried to reach does not exist.'
                        : 'Sidan du försökte nå finns tyvärr inte.'}
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        gap: isMobile ? 30 : 50,
                        paddingBlockStart: 20,
                    }}
                >
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                    <div style={buttonStyles} onClick={() => navigate(-1)}>
                        {language === 'en' ? 'Go back' : 'Gå tillbaka'}
                    </div>
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                    <div style={buttonStyles} onClick={() => navigate('/')}>
                        <Link to="/">
                            {language === 'en' ? 'Go to home page' : 'Gå till startsidan'}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NotFoundPage;
